import React from 'react';

function NoMatch() {
  return (
    <div>
      <h2>Sorry</h2>
      <p>
        We can not find anything here.
      </p>
    </div>
  );
}

export default NoMatch;
